@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@layer base {
	body {
		@apply bg-gray-50 text-gray-700 selection:bg-indigo-600 selection:text-white;
	}

	h1,
	h2,
	h3 {
		@apply text-gray-900;
	}

	a {
		@apply font-bold text-indigo-600 transition hover:text-indigo-700 focus:outline-indigo-600;
	}

	label {
		@apply mb-1 block font-medium;
	}

	[type='text'],
	[type='email'],
	[type='url'],
	[type='password'],
	[type='number'],
	[type='date'],
	[type='datetime-local'],
	[type='month'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='week'],
	[multiple],
	textarea,
	select {
		@apply w-full rounded-lg border-gray-300 placeholder:text-gray-400;
	}

	[type='text']:focus,
	[type='email']:focus,
	[type='url']:focus,
	[type='password']:focus,
	[type='number']:focus,
	[type='date']:focus,
	[type='datetime-local']:focus,
	[type='month']:focus,
	[type='search']:focus,
	[type='tel']:focus,
	[type='time']:focus,
	[type='week']:focus,
	[multiple]:focus,
	textarea:focus,
	select:focus {
		@apply shadow-indigo-600 ring-indigo-600;
	}

	button,
	[type='button'],
	[type='reset'],
	[type='submit'] {
		@apply rounded-lg bg-indigo-600 py-2.5 px-6 text-center text-lg font-bold text-white transition hover:bg-indigo-700 hover:shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;
	}
}

@layer components {
	.required-input-label {
		@apply after:ml-1 after:text-red-600 after:content-["*"];
	}
}
